import { Subject } from "rxjs"

const subject = new Subject()
export const SalesForceCustomerList = {
  setSalesForceCustomerListValue: (salesRepCustomerList: any) => subject.next({ salesRepCustomerList: salesRepCustomerList }),
  getSalesForceCustomerListValue: () => subject.asObservable(),
  clearSalesForceCustomerListValue: () => subject.next({ salesRepCustomerList: {} }),
}
export const SalesForceCustomer = {
  setSalesForceCustomerValue: (salesRepCustomer: any) => subject.next({ salesRepCustomer: salesRepCustomer }),
  getSalesForceCustomerValue: () => subject.asObservable(),
  clearSalesForceCustomerValue: () => subject.next({ salesRepCustomer: {} }),
}
export const salesForceCustomerDataSaveButton = {
  setSaveButtonIsClicked: (saveButtonIsClicked: boolean) => subject.next({ saveButtonIsClicked: saveButtonIsClicked }),
  getSaveButtonIsClicked: () => subject.asObservable(),
  clearSaveButtonIsClicked: () => subject.next({ saveButtonIsClicked: false }),
}
export const CloneAssortmentProducts = {
  setCloneAssortmentProductsSuccess: (assortmentProducts: any) => subject.next({ assortmentProducts: assortmentProducts }),
  getCloneAssortmentProductsSuccess: () => subject.asObservable(),
  clearCloneAssortmentProductsSuccess: () => subject.next({ assortmentProducts: false }),
}
export const AssortmentProductVisibilitySet = {
  setAssortmentProductVisibilitySuccess: (visibility: any) => subject.next({ visibility: visibility }),
  getAssortmentProductVisibilitySuccess: () => subject.asObservable(),
  clearAssortmentProductVisibilitySuccess: () => subject.next({ visibility: false }),
}
export const DeleteAssortmentProductById = {
  setDeleteAssortmentProductByIdSuccess: (isDeleted: any) => subject.next({ isDeleted: isDeleted }),
  getDeleteAssortmentProductByIdSuccess: () => subject.asObservable(),
  clearDeleteAssortmentProductByIdSuccess: () => subject.next({ isDeleted: false }),
}
export const DeleteAllAssortmentProduct = {
  setDeleteAllAssortmentProductSuccess: (isDeletedAll: any) => subject.next({ isDeletedAll: isDeletedAll }),
  getDeleteAllAssortmentProductSuccess: () => subject.asObservable(),
  clearDeleteAllAssortmentProductSuccess: () => subject.next({ isDeletedAll: false }),
}

export const AssortmentProductRangeCreated = {
  setAssortmentProductRangeCreatedSuccess: (isRangeCreated: any) => subject.next({ isRangeCreated: isRangeCreated }),
  getAssortmentProductRangeCreatedSuccess: () => subject.asObservable(),
  clearAssortmentProductRangeCreatedSuccess: () => subject.next({ isRangeCreated: false }),
}

export const OfferCreated = {
  setOfferCreatedSuccess: (isOfferCreated: any) => subject.next({ isOfferCreated: isOfferCreated }),
  getOfferCreatedSuccess: () => subject.asObservable(),
  clearOfferCreatedSuccess: () => subject.next({ isOfferCreated: false }),
}

export const FilterPriceChanged = {
  setFilterPriceChangedSuccess: (isFilterPriceChanged: any) => subject.next({ isFilterPriceChanged: isFilterPriceChanged }),
  getFilterPriceChangedSuccess: () => subject.asObservable(),
  clearFilterPriceChangedSuccess: () => subject.next({ isFilterPriceChanged: false }),
}
export const MainDropdownChanged = {
  setMainDropdownChangedSuccess: (propertyKey: any) => subject.next({ propertyKey: propertyKey }),
  getMainDropdownChangedSuccess: () => subject.asObservable(),
  clearMainDropdownChangedSuccess: () => subject.next({ propertyKey: "" }),
}
export const MPNArticleSearchChanged = {
  setMPNArticleSearchChangedSuccess: (mpnArticleSearchText: any) => subject.next({ mpnArticleSearchText: mpnArticleSearchText }),
  getMPNArticleSearchChangedSuccess: () => subject.asObservable(),
  clearMPNArticleSearchChangedSuccess: () => subject.next({ mpnArticleSearchText: "" }),
}
export const AssortmentCartChanged = {
  setAssortmentCartChangedSuccess: (isAssortmentCartChanged: any) => subject.next({ isAssortmentCartChanged: isAssortmentCartChanged }),
  getAssortmentCartChangedSuccess: () => subject.asObservable(),
  clearAssortmentCartChangedSuccess: () => subject.next({ isAssortmentCartChanged: false }),
}

export const IndexDBChanged = {
  setIndexDBChangedSuccess: (isIndexDBChanged: any) => subject.next({ isIndexDBChanged: isIndexDBChanged }),
  getIndexDBChangedSuccess: () => subject.asObservable(),
  clearIndexDBChangedSuccess: () => subject.next({ isIndexDBChanged: false }),
}
