export class IndexedDbService {
    private readonly dbName: string;
    private readonly version: number;
    private readonly stores: Array<{ name: string, options: IDBObjectStoreParameters }>;
    private db: IDBDatabase | null;

    constructor(dbName: string, version: number, stores: Array<{ name: string, options: IDBObjectStoreParameters }>) {
        this.dbName = dbName;
        this.version = version;
        this.stores = stores;
        this.db = null;
    }

    public async open(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
          if (window && window.indexedDB) {
            const request = window.indexedDB.open(this.dbName, this.version);
            // @ts-ignore
            request.onerror = (event) => reject(event.target.error);
            // @ts-ignore
            request.onupgradeneeded = (event) => this._createStores(event.target.result);
            request.onsuccess = (event) => {
              // @ts-ignore
              // console.log("open:result",event.target.result);
              // @ts-ignore
              this.db = event.target.result;
              resolve();
            };
          }
        });
    }

    public async getAll<T = any>(storeName: string): Promise<T | undefined> {
        return new Promise<T | undefined>((resolve, reject) => {
            if (this.db) {
                const transaction = this.db!.transaction(storeName, "readonly");
                const store = transaction.objectStore(storeName);
                const request = store.getAll();
                // @ts-ignore
                request.onsuccess = (event) => resolve(event.target.result);
                // @ts-ignore
                request.onerror = (event) => reject(event.target.error);
            }
        });
    }

    public async get<T = any>(storeName: string, key: IDBValidKey): Promise<T | undefined> {
        return new Promise<T | undefined>((resolve, reject) => {
            if (this.db) {
                const transaction = this.db!.transaction(storeName, "readonly");
                const store = transaction.objectStore(storeName);
                const request = store.get(key);
                // @ts-ignore
                request.onsuccess = (event) => resolve(event.target.result);
                // @ts-ignore
                request.onerror = (event) => reject(event.target.error);
            }
        });
    }

    public async set(storeName: string, data: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this.db) {
                const transaction = this.db!.transaction(storeName, "readwrite");
                const store = transaction.objectStore(storeName);
                const request = store.put(data);
                request.onsuccess = (event) => resolve();
                // @ts-ignore
                request.onerror = (event) => reject(event.target.error);
            }
        });
    }

    public async delete(storeName: string, key: IDBValidKey): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this.db) {
                const transaction = this.db!.transaction(storeName, "readwrite");
                const store = transaction.objectStore(storeName);
                const request = store.delete(key);
                request.onsuccess = (event) => resolve();
                // @ts-ignore
                request.onerror = (event) => reject(event.target.error);
            }
        });
    }

    public isDbConnected(): boolean {
        return this.db !== null;
    }

    public async connectWithIndexedDb() {
        try {
            if (!this.isDbConnected()) {
                await this.open();
            }
        } catch (error) {
            console.error(error);
        }
    }

    public close(): void {
        if (this.db) {
            this.db.close();
            this.db = null;
        }
    }

  // public clearIndexDB() {
  //     // open a read/write db transaction, ready for clearing the data
  //     const transaction = this.db!.transaction(["assortmentItemObject"], "readwrite");
  //     if (transaction) {
  //         // create an object store on the transaction
  //         const objectStore = transaction.objectStore("assortmentItemObject");
  //         if (objectStore) {
  //             // Make a request to clear all the data out of the object store
  //             objectStore.clear();
  //         }
  //     }
  // };

  public async clearIndexDB(storeName: string) {
    return new Promise<void>((resolve, reject) => {
      if (this.db) {
        const transaction = this.db!.transaction(storeName, "readwrite");
        if (!transaction) {
          reject(new Error(`Failed to open transaction for store ${storeName} in ${this.dbName}`));
        }
        const objectStore = transaction.objectStore(storeName);
        if (!objectStore) {
          reject(new Error(`Failed to open object store ${storeName} in ${this.dbName}`));
        }
        const request = objectStore.clear();

        request.onsuccess = () => resolve();

        request.onerror = (event: any) => reject(event.target.error);
      } else {
        reject(new Error(`Database not found or not opened`));
      }
    });
  };

    private _createStores(db: IDBDatabase): void {
        for (const store of this.stores) {
            if (!db.objectStoreNames.contains(store.name)) {
                db.createObjectStore(store.name, store.options);
            }
        }
    }
}
