/* eslint-disable @typescript-eslint/ban-ts-comment */
import { defineStore } from "pinia"
import { HttpService } from "@/helpers/http-service"
import { CONSTANT } from "@/helpers/constant"
import _ from "lodash"
import { Context } from "@/types/systemContext"
import { ApiResponse } from "@/types/apiResponse"
import { SalesChannel } from "@/types/sales-channel/salesChannel"
import { Country } from "@/types/country"
import { Salutation } from "@/models/address.model"
import { Language } from "@/types/language"
import { Tag } from "@/types/tag"
import { States } from "@/types/states"
import { Currency } from "@/types/currency/currency"

const httpService = new HttpService()

interface State {
  salutations: Salutation[]
  countries: Country[]
  defaultCurrency: Currency | undefined | null
  states: States[]
  currencies: Currency[]
  languages: Language[]
  defaultLanguage: Language
  currentContext: Context | null
  tags: Tag[]
  salesChannel: SalesChannel[] | null
  isTagUpdated: boolean
}

export const useSystemAndContextStore = defineStore({
  id: "systemAndContextStore",
  state: (): State => ({
    salutations: [],
    countries: [],
    defaultCurrency: {} as Currency,
    states: [],
    currencies: [],
    languages: [],
    defaultLanguage: {} as Language,
    currentContext: {} as Context,
    tags: [],
    salesChannel: [],
    isTagUpdated: false,
  }),
  getters: {
    getSalutations: (state) => {
      return state.salutations
    },
    getCountries: (state) => {
      return state.countries
    },

    getCurrencies: (state) => {
      return state.currencies
    },
    getDefaultCurrency: (state) => {
      return state.defaultCurrency
    },
    getCurrentContext: (state) => {
      return state.currentContext
    },
    getCurrentSalesChannel: (state) => {
      return state.salesChannel
    },
    getLanguages: (state) => {
      return state.languages
    },
    getDefaultLanguage: (state) => {
      return state.defaultLanguage
    },
  },
  actions: {
    /**
     * Fetch salutations
     */
    async fetchSalutations() {
      try {
        const result: ApiResponse = await httpService.post(`${CONSTANT.API.SYSTEM_CONTEXT.FETCH_SALUTATION}`)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          const _salutations = _.get(result, "data.elements", []) as Salutation[]
          if (_salutations && _salutations.length > 0) {
            this.salutations = _salutations
          }
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    /**
     * Fetch countries
     */
    async fetchStatesByCountryId(countryId: string) {
      try {
        const fetchStatesByCountryIdUrl = CONSTANT.API.SYSTEM_CONTEXT.FETCH_STATE.replace("{countryId}", countryId)
        const result: ApiResponse = await httpService.get(fetchStatesByCountryIdUrl)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          this.states = _.get(result, "data.elements", []) as States[]
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    async fetchCountries() {
      try {
        this.countries = []
        let page = 1
        const limit = 100
        let result: any = true
        while (result) {
          result = await httpService.post(`${CONSTANT.API.SYSTEM_CONTEXT.FETCH_COUNTRY}`, {
            limit: limit,
            page: page,
          })
          if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
            const _countries = _.get(result, "data.elements", null) as Country[]
            if (!_countries || _countries.length <= 0) {
              result = null
              continue
            }
            this.countries.push(..._countries)
            if (_countries.length < limit) {
              result = null
              continue
            }
            page++
          }
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    /**
     * Fetch the current context
     * Fetches the current context. This includes for example the customerGroup, currency, taxRules and many more.
     */
    async fetchCurrentContext() {
      try {
        const result: ApiResponse = await httpService.get(`${CONSTANT.API.SYSTEM_CONTEXT.FETCH_CONTEXT}`)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          const _context = _.get(result, "data", null) as Context
          if (_context) {
            this.currentContext = _context
            this.defaultCurrency = _context?.salesChannel?.currency
          }
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    /**
     * Fetch languages
     */
    async fetchLanguages() {
      try {
        const result: ApiResponse = await httpService.post(`${CONSTANT.API.SYSTEM_CONTEXT.FETCH_LANGUAGE}`)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          const _languages = _.get(result, "data.elements", []) as Language[]
          if (_languages && _languages.length > 0) {
            this.languages = _languages
            const defaultLanguageId = _.get(this.currentContext, "salesChannel.languageId", null)
            this.defaultLanguage = this.languages.find((language: Language) => language.id === defaultLanguageId)!
            if (this.defaultLanguage) {
              localStorage.setItem("defaultLanguage", JSON.stringify(this.defaultLanguage))
            }
          }
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    /**
     * Get tags
     */
    async getTags() {
      try {
        const result: ApiResponse = await httpService.post(`${CONSTANT.API.SYSTEM_CONTEXT.GET_TAG}`)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          this.tags = _.get(result, "data", []) as Tag[]
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    /**
     * Get salesChannel
     */
    async getSalesChannel() {
      try {
        const result: ApiResponse = await httpService.post(`${CONSTANT.API.SYSTEM_CONTEXT.GET_SALES_CHANNEL}`)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          this.salesChannel = _.get(result, "data.elements", [])
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    /**
     * Create visibility
     */
    async createVisibility(assortmentId: string, visibilityId: string) {
      try {
        const createVisibilityUrl = CONSTANT.API.SYSTEM_CONTEXT.CREATE_VISIBILITY.replace("{id}", assortmentId).replace("{visibilityId}", visibilityId)
        const result: any = await httpService.get(createVisibilityUrl)
        return !!(result && (result.status === CONSTANT.HTTP_STATUS.OK || result.status === CONSTANT.HTTP_STATUS.NO_CONTENT))
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    }, 
  },
})
