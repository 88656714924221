import {isNumber} from "lodash";

export default function formatPrice(price: number, locale: string = 'de-DE', currency: string = 'EUR') {
  return Intl.NumberFormat(locale, {style: 'currency', currency: currency}).format(price);
}

export function formatAmount(price: number, locale: string = 'de-DE') {
    return Intl.NumberFormat(locale).format(price);
}

export function formatDiscount(price: number) {
    return Math.round(price) + ' %';
}

export function getNetPrice(grossPrice: number, taxRate: number): number {
    if(!isNumber(grossPrice) || grossPrice < 0){
        taxRate = 0;
    }
    if(!isNumber(taxRate)){
        taxRate = 0;
    }
    return grossPrice / (1. + taxRate / 100);
}
